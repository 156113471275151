
  import Vue from "vue"

  export default Vue.extend({
    name: "DatePicker",
    props: {
      date: {
        type: String,
        default: ""
      },
      maxDate: {
        type: String,
        default: ""
      },
      dateInit: {
        type: Boolean,
        default: false
      },
      stepInit: {
        type: String,
        default: "DATE"
      },
      dateLabel: String,
      errorMessages: {
        type: [String, Array],
        default: ""
      }
    },
    data() {
      return {
        pickedDate: '',
        dateFormatted: null as null | string,
        activePicker: "" as string,
        menu: false
      }
    },
    watch: {
      date (val) {
        this.pickedDate = val
        this.saveDate()
      },
      pickedDate (val){
        this.$emit('update-date', val)
      },
      menu(val: boolean) {
        val && setTimeout(() => (this.activePicker = this.stepInit))
      },
    },
    mounted() {
      
      if (this.dateInit) {
        let date = new Date(this.date)
        this.dateFormatted =
          `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth()+1)).slice(-2)}/${date.getFullYear()}`
      }
    },
    methods: {
      saveDate() {
        // 'day-month-year' format for the text field
        if (this.date) {
          this.dateFormatted = `${this.date?.slice(
            8,
            10
          )}/${this.date?.slice(5, 7)}/${this.date?.slice(0, 4)}`
        } else {
          this.dateFormatted = null
        } 
      },
      clearDate() {
        this.dateFormatted = null
        this.pickedDate = ''
        this.$emit('update-date', '')
      },
    }
  })
